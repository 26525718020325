<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1300px" @close="close">
        <v-card>
          <v-card-title class="justify-center align-center py-3">
            {{ object.titulo }}
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="white--text"
              @click="exportar(object.codigo)"
            >
              Descargar PDF
              <v-icon right dark>mdi-cloud-download</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="pb-7">
              <v-col v-if="object.subtitulo !== ''" class="text-lg-center" cols="12" sm="12" md="12">
                <h4 style="text-align: center">{{ object.subtitulo }}</h4>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <div>
                  <v-data-table
                    dense
                    :headers="object.tiporeferencia != 'S' ? cParticipantes : cParticipantesSeg"
                    :items="object.invitados"
                    :items-per-page="-1"
                    sort-by="calories"
                    class="border"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <div
                        class="primary w-100 d-flex justify-space-between px-2 white--text body-1"
                        style="height: 40px;"
                      >
                        <div class="d-flex align-center py-1">
                          INVITADOS/PARTICIPANTES
                          <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                          <div v-if="object.tiporeferencia != 'S'" class="d-flex align-center">
                            {{ object.porcparticipantes }} %
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.lider`]="{ item }">
                      <v-simple-checkbox
                        v-if="parseInt(item.lider) === 1"
                        :value="true"
                        :ripple="false"
                        disabled
                      ></v-simple-checkbox>
                      <v-simple-checkbox v-else :ripple="false" disabled></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.estado`]="{ item }">
                      <v-icon v-if="item.estado === 'S'" color="green darken-2">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                      <v-icon v-else-if="item.estado === 'I'" color="blue darken-2">mdi-minus-circle-outline</v-icon>
                      <v-icon v-else-if="item.estado === 'N'" color="red darken-2">mdi-close-circle-outline</v-icon>
                    </template>
                    <template v-slot:no-data>
                      <span>No se encontró información</span>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
            <v-divider v-if="object.tiporeferencia != 'S'"></v-divider>
            <v-row v-if="object.tiporeferencia != 'S'">
              <v-col cols="12" sm="6">
                <h4>Escribano: {{ object.escribano }}</h4>
              </v-col>
            </v-row>
            <v-divider v-if="object.tiporeferencia != 'S'"></v-divider>
            <v-row class="pt-4" v-if="object.tiporeferencia != 'S'">
              <v-col cols="12" sm="12">
                <div>
                  <v-data-table
                    dense
                    :headers="cAgenda"
                    :items="object.agendas"
                    :items-per-page="-1"
                    sort-by="calories"
                    class="border"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <div class="primary w-100 d-flex justify-space-between px-2" style="height: 35px;">
                        <div class="d-flex align-center white--text body-1">
                          AGENDA
                          <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                        </div>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <span>No se encontró información</span>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="pt-0">
                <div>
                  <v-data-table
                    dense
                    :headers="cAcuerdos"
                    :items="object.compromisos"
                    :items-per-page="-1"
                    sort-by="calories"
                    class="border"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <div class="primary w-100 d-flex justify-space-between px-2" style="height: 35px;">
                        <div class="d-flex align-center white--text body-1">
                          ACUERDOS
                          <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.responsables`]="{ item }">
                      <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>
                          <ul class="common-list my-2">
                            <li :key="i" v-for="(e, i) in item.responsables">
                              <b v-if="e.isResponsibleItem">{{ e.responsable }}</b>
                              <span v-else>{{ e.responsable }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.area`]="{ item }">
                      <div style="display: flex;align-items: center;">
                        <div>
                          <ul class="common-list my-2">
                            <li :key="i" v-for="(e, i) in item.responsables">
                              {{ e.subarea ? e.subarea : 'OTROS' }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.fechafin`]="{ item }">
                      {{
                        item.fechafin
                          .split('-')
                          .reverse()
                          .join('-')
                      }}
                    </template>
                    <template v-slot:[`item.estatus`]="{ item }">
                      <v-chip v-if="item.estatus === 'RE'" color="green" dark> Realizado</v-chip>
                      <v-chip v-else-if="item.estatus === 'PV'" color="blue" dark> Por Vencer</v-chip>
                      <v-chip v-else-if="item.estatus === 'NR'" color="red" dark> No Realizado</v-chip>
                      <v-chip v-else-if="item.estatus === 'CA'" color="back" dark>Cancelado</v-chip>
                    </template>
                    <template v-slot:no-data>
                      <span>No se encontró información</span>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" text @click="$emit('closeActasView')">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import CompromisoService from '../services/CompromisoService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'ActasView',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object,
      default: () => ({
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        tiporeferencia: '',
        nombresall: ''
      })
    }
  },
  data: () => ({
    loading: false,
    matrizService: null,
    cParticipantes: [
      { text: 'Nombres', value: 'nombresall' },
      { text: 'Dni', value: 'idtrabajador' },
      { text: 'Correo', value: 'email' },
      { text: 'Lider', value: 'lider' },
      { text: 'Estado', value: 'estado' }
    ],
    cParticipantesSeg: [
      { text: 'Nombres', value: 'nombresall' },
      { text: 'Dni', value: 'idtrabajador' },
      { text: 'Correo', value: 'email' }
    ],
    cAgenda: [
      { text: 'N°', align: 'start', value: 'item', width: '100' },
      { text: 'Descripción', value: 'descripcion' }
    ],
    cAcuerdos: [
      { text: 'N°', align: 'start', value: 'item', width: '100' },
      { text: 'Compromisos', value: 'nombrecompromiso', width: '160' },
      { text: 'Responsable', value: 'responsables' },
      { text: 'Área', value: 'area' },
      { text: 'Fecha', value: 'fechafin' },
      { text: 'Estado', value: 'estatus' }
    ],
    itemsStatusResponsible: [
      { value: 'RE', color: 'green', text: 'Realizado' },
      { value: 'PV', color: 'blue', text: 'Por Vencer' },
      { value: 'NR', color: 'red', text: 'No Realizado' },
      { value: 'CA', color: 'back', text: 'Cancelado' }
    ],
    itemsStatus: [
      { value: 'S', text: 'Si' },
      { value: 'I', text: 'Invitado' },
      { value: 'N', text: 'No' }
    ]
  }),
  watch: {},
  methods: {
    close() {
      this.$emit('closeActasView');
    },
    async exportar(codigo) {
      this.loading = true;
      const token = localStorage.getItem('token');
      const empresa = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'descargaracta',
        {
          idempresa: empresa,
          token: token,
          idacta: codigo
        },
        `${this.object.numeroacta}-${moment().format('YYYYMMDDHHMM')}.pdf`
      );
      this.loading = false;
    }
  },
  async created() {
    this.matrizService = this.$httpService(new CompromisoService(), this);
  }
};
</script>
